/* eslint-disable brace-style */
/* eslint-disable security/detect-object-injection */
import axios from 'axios'
import Agent from 'agentkeepalive'
import { ServerContext, ExternalHeaders } from '../core/interfaces'
import { BareFetcher } from 'swr'

const httpAgent = new Agent({
  keepAlive: true,
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000,
  freeSocketTimeout: 30000,
})

const httpsAgent = new Agent.HttpsAgent({
  keepAlive: true,
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000,
  freeSocketTimeout: 30000,
})

const client = axios.create({
  httpAgent,
  httpsAgent,
})

const processStoreQueryParams = (context) => {
  let queryParams = ''
  const query = context?.req?.query || {}
  if (query['store']) {
    queryParams += `&store=${query['store']}`
  }
  if (query['storeId']) {
    queryParams += `&storeId=${query['storeId']}`
  }
  return queryParams
}

const fetcher = <T>(context?: ServerContext, externalHeaders?: ExternalHeaders): BareFetcher<T> => {
  const isServer = !!context?.req
  const baseUrl = isServer
    ? process.env.JOKER_URL
    : window.location.origin || ''
  const options = isServer
    ? { headers: { cookie: context.req.headers.cookie || '' } }
    : { withCredentials: true, headers: externalHeaders }

  return async (url: string): Promise<T> => {
    const fullUrl = new URL(
      `${url}${processStoreQueryParams(context)}`,
      baseUrl
    )

    const response = await client.get(fullUrl.toString(), options)
    return response.data
  }
}

export default fetcher
