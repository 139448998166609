/* eslint-disable security/detect-object-injection */
import useFetch from './useFetch'
import { IBlock, IApplicationState } from '@bees-web/core/types'
import { ExternalHeaders, QueryParams } from '../interfaces'
import { processUrlStateExpression, urlToObjectParams } from '../tools/url'
import { jokerUrlBuilder } from '../tools/joker'
import { usePageContext } from './usePageContext'

export const blockUrlBuilder = (
  url: string,
  params: QueryParams,
  state: IApplicationState = null
): string =>
  jokerUrlBuilder('blocks', {
    ...urlToObjectParams(processUrlStateExpression(url, state)),
    ...params,
  })

export default function useBlockSchema(url: string, headers: ExternalHeaders): {
  isLoading: boolean
  error: any
  block: IBlock
} {
  const {
    state,
    locale,
    accountId: selectedPocAccount,
    storeId,
    query,
  } = usePageContext()

  const coreQs = {
    ...query,
    locale,
    ...(selectedPocAccount ? { selectedPocAccount } : {}),
    ...(storeId ? { storeId } : {}),
  }

  const blockUrl = blockUrlBuilder(url, coreQs, state)

  const { data: block, error, isLoading } = useFetch<IBlock>(blockUrl, headers)

  if (error) {
    console.error(blockUrl, error)
  }

  return { isLoading, error, block }
}
